import React, { useEffect } from "react";
import Navigation from "../../Components/Navigation";
import Footer from "../../Components/Footer";
import Grid from "@mui/material/Grid2";
export default function GreenJobs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  document.title="Green Jobs"
  }, []);
  return (
    <main>
      <Navigation />
      <section className="greenjobs-container">
        <Grid container size={12} className="greenjobs-title">
          <h1>Creating Opportunities for a Sustainable Future</h1>
        </Grid>
      </section>
      {/* Green Jobs Pipeline Section */}
      <section>
        <Grid
          container
          size={12}
          justifyContent="center"
          className="pipeline-container"
        >
          <Grid container size={12} justifyContent="center">
            <h1 textAlign="center">Green Jobs Pipeline</h1>
          </Grid>
          <Grid size={{ xs: 12, lg: 10 }} style={{ paddingTop: "3%" }}>
            <p>
            EMPOWER is working with the Green Jobs Advisory Council to create a robust green jobs pipeline in Alachua County. By providing training, internships,
              and employment opportunities, EMPOWER aims to equip residents with
              the skills and knowledge necessary to participate in the growing
              clean energy sector.
            </p>
            <ul className="pipeline-list">
              <li className="pipeline-spacing">
                <span style={{ fontWeight: "bold" }}>Training Programs: </span>
                EMPOWER collaborates with local educational institutions, such
                as Santa Fe College, to offer specialized training programs in
                areas like solar installation, energy efficiency, and renewable
                energy technologies.
              </li>
              <li className="pipeline-spacing">
                <span style={{ fontWeight: "bold" }}>Internships: </span>
                EMPOWER is working with Santa Fe College and CareerSource NCFL to facilitate internships with local green jobs
                businesses and organizations, providing students and job seekers
                with hands-on experience and networking opportunities.
              </li>
              <li className="pipeline-spacing">
                <span style={{ fontWeight: "bold" }}>
                  Employment Placement:{" "}
                </span>
                EMPOWER works with employers to identify and fill green job
                vacancies, connecting qualified individuals with career
                opportunities in the clean energy sector.
              </li>
            </ul>
            <p>
              By investing in the development of a green jobs pipeline, EMPOWER
              is helping to create a skilled workforce that can support the
              county's transition to a clean energy economy.
            </p>
          </Grid>
        </Grid>
      </section>
      {/* Economic Impact Section */}
      <section>
        <Grid
          container
          size={12}
          justifyContent="center"
          className="impact-container"
        >
          <Grid container size={12} justifyContent="center">
            <h1 textAlign="center">Economic Impact</h1>
          </Grid>
          <Grid size={{ xs: 12, lg: 10 }} style={{ paddingTop: "3%" }}>
            <p>
              A thriving clean energy sector can have a significant economic
              impact on Alachua County. By investing in renewable energy
              projects and energy efficiency initiatives, the county can create
              new jobs, stimulate local economic development, and attract
              businesses.
            </p>
            <ul className="pipeline-list">
              <li className="pipeline-spacing">
                <span style={{ fontWeight: "bold" }}>Job Creation: </span>
                The clean energy sector is expected to experience significant
                job growth in the coming years. By developing a skilled
                workforce and supporting local businesses, EMPOWER can
                contribute to the creation of new jobs in Alachua County.
              </li>
              <li className="pipeline-spacing">
                <span style={{ fontWeight: "bold" }}>
                  Local Economic Development:{" "}
                </span>
                Clean energy projects can stimulate local economic development
                by generating revenue for businesses, property owners, and the
                government.
              </li>
              <li className="pipeline-spacing">
                <span style={{ fontWeight: "bold" }}>
                  Attracting Businesses:{" "}
                </span>
                A strong clean energy sector can attract new businesses and
                investments to the county, further boosting the local economy.
              </li>
            </ul>
          </Grid>
        </Grid>
      </section>
      {/* Community Benefits Section */}
      <section>
        <Grid
          container
          size={12}
          justifyContent="center"
          className="pipeline-container"
        >
          <Grid container size={12} justifyContent="center">
            <h1 textAlign="center">Community Benefits</h1>
          </Grid>
          <Grid size={{ xs: 12, lg: 10 }} style={{ paddingTop: "3%" }}>
            <p>
              Green jobs can provide numerous benefits for underserved
              communities in Alachua County. By offering opportunities for
              employment and training, EMPOWER can help to reduce poverty,
              increase income levels, and improve the overall quality of life.
            </p>
            <ul className="pipeline-list">
              <li className="pipeline-spacing">
                <span style={{ fontWeight: "bold" }}>Increased Income: </span>
                Green jobs can provide a stable income for individuals and
                families, helping to meet basic needs and improve financial
                security.
              </li>
              <li className="pipeline-spacing">
                <span style={{ fontWeight: "bold" }}>
                  Improved Quality of Life:{" "}
                </span>
                Employment in the clean energy sector can lead to increased job
                satisfaction, better working conditions, and opportunities for
                career advancement.
              </li>
              <li className="pipeline-spacing">
                <span style={{ fontWeight: "bold" }}>
                  Community Empowerment:{" "}
                </span>
                Green jobs can empower individuals and communities by providing
                them with the skills and resources to participate in the clean
                energy transition and contribute to a more sustainable future.
              </li>
            </ul>
          </Grid>
        </Grid>
      </section>
      <Footer />
    </main>
  );
}
