import React, { useEffect } from "react";
import Navigation from "../../Components/Navigation";
import Footer from "../../Components/Footer";

import Grid from "@mui/material/Grid2";

import familypicSM from "../../Images/familysmilingSM.png";
export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
       document.title="About Us"
  }, []);
  return (
    <main>
      <Navigation />

      <section className="ourstory-container">
        <Grid container size={12} justifyContent="center">
          <Grid container size={12} className="story-title">
            <h1>Our Story</h1>
          </Grid>
          <Grid
            container
            alignItems="center"
            size={{ xs: 11, md: 10, lg: 8 }}
            className="empower-frame-plug"
          >
            <h4
              className="frame-text"
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              Project EMPOWER, a cornerstone of the EMPOWER Coalition, emerged
              from a 2021 NAACP forum focused on transitioning Alachua County to
              100% clean energy. Recognizing the need for a just and equitable
              transition, EMPOWER was established to address energy inequity,
              create green job opportunities, and promote sustainable energy
              solutions.
            </h4>
          </Grid>
        </Grid>
      </section>


 <section  >
        <Grid container size={12} className='signed-section' justifyContent="center"  backgroundColor='#E7E7E7'>
        <Grid
        container
        item
          size={{ xs: 12, md: 12, lg: 12, xl: 10 }}
  
          justifyContent='center'
        >
           <Grid container item paddingBottom='2%'textAlign='center' >
          <h2>Coalition members who signed the “Letter of Collaboration” in January of 2023:</h2>
        </Grid>
      <Grid size={12} container justifyContent='center'>
     
          <Grid size={{xs:11, md:6}} justifyContent='center'>
          <Grid item>
  <li className='signed-spacing'>Alachua County</li>
  <li className='signed-spacing'>Alachua County NAACP Environmental and Climate Justice Committee (ECJC)</li>
  <li className='signed-spacing'>Alachua County Public Schools</li>
  <li className='signed-spacing'>CareerSource North Central Florida</li>
  <li className='signed-spacing'>City of Gainesville</li>
  <li className='signed-spacing'>Community Weatherization Coalition (CWC)</li>
  <li className='signed-spacing'>Cultural Arts Coalition (CAC)</li>
  </Grid>
  </Grid>
   <Grid container size={{xs:11, md:6}} justifyContent='center'>
    <Grid item>
  <li className='signed-spacing'>Greater Duval Neighborhood Association (GNDA)</li>
  <li className='signed-spacing'>Rebuilding Together North Central Florida (RTNCF)</li>
  <li className='signed-spacing'>Santa Fe College</li>
  <li className='signed-spacing'>Solar Impact, Inc.</li>
  <li className='signed-spacing'>Springhill Neighborhood Association</li>
  <li className='signed-spacing'>Southwest Advocacy Group (SWAG)</li>
  </Grid>
  </Grid>

</Grid>
        </Grid>
        
        </Grid>
      </section> 

      <section>
        <Grid
          container
          size={12}
          justifyContent="center"
          className="milestone-container"
        >
          <Grid container size={12} justifyContent="center">
            <h1 textAlign="center">Key Milestones and Accomplishments</h1>
          </Grid>
          <Grid size={{ xs: 12, lg: 10 }} style={{ paddingTop: "3%" }}>
            <ul className="milestone-list">
              <li className="milestone-spacing">
                <span style={{ fontWeight: "bold" }}>
                  Community Engagement:{" "}
                </span>
                EMPOWER works closely with leaders and representatives of Greater Duval, Springhill/Sugarhill, SWAG, and other underserved communities to develop projects and activities
              </li>
              <li className="milestone-spacing">
                <span style={{ fontWeight: "bold" }}>
                  Green Jobs:{" "}
                </span>
                EMPOWER’s Green Jobs Advisory Council is working to develop a green jobs pipeline, partnering with Alachua County Public Schools, CareerSource NCFL, and Santa Fe College to offer enrichment and training programs as well as internships in diverse green jobs.
              </li>
           
              <li className="milestone-spacing">
                <span style={{ fontWeight: "bold" }}>
                  Technical Assistance:{" "}
                </span>
                Through its participation in the Communities LEAP program,
                EMPOWER has received valuable technical assistance to support
                its clean energy goals. As a key partner within the Communities LEAP program, NREL has
                offered expert guidance and support to EMPOWER in areas such as
                solar, weatherization, and green jobs. U.S. Department of Energy (USDOE): EMPOWER has received valuable
                support from the USDOE through the Communities LEAP program,
                which provides technical assistance and resources for
                community-led clean energy initiatives.
              </li>
            </ul>
            <ul className="milestone-list">
              <li className="milestone-spacing">
                <span style={{ fontWeight: "bold" }}>
                  A Collaborative Approach:{" "}
                </span>{" "}
                EMPOWER’s success is rooted in its collaborative approach. By
                working closely with community leaders, organizations, and
                government agencies, EMPOWER has been able to leverage diverse
                expertise and resources to achieve its objectives.
              </li>
          
            
            
            </ul>
          </Grid>

         
        </Grid>
      </section>
      <section>
        <Grid
          container
          size={10}
          justifyContent="center"
          className="challenge-container"
        >
          <Grid
            container
            size={12}
            justifyContent="center"
            className="challenge-header"
          >
            <h1 textAlign="center">The Energy Burden: A Growing Challenge</h1>
          </Grid>
          <Grid size={{ xs: 12, lg: 6 }}>
            
              <h4>The Interconnectedness of Factors</h4>
              <p>
                These factors are interconnected and can exacerbate energy
                inequity. For example, low-income families living in older,
                inefficient homes may have limited financial resources to make
                necessary upgrades. Additionally, lack of awareness about
                energy-efficiency programs can further perpetuate the cycle of
                high energy costs. Addressing energy inequity requires a
                multifaceted approach that targets these root causes. By
                improving housing conditions, promoting energy efficiency, and
                expanding access to support programs, we can work towards a more
                equitable and sustainable energy future for all.
              </p>
            <h4>A Disproportionate Burden</h4>
            <p>
              Low-income families in Alachua County face a disproportionately
              high energy burden, spending an average of 22% of their annual
              income on household energy costs. This is significantly higher
              than the average of 5.5% for all families in the county. This
              excessive expenditure can lead to financial hardship and limit
              access to essential resources.
            </p>
            <h4>Marginalized Groups: A Focus of Concern</h4>
            <p>
              <span >Racial Disparities: </span>
              Black residents in Alachua County are more likely to live in
              older, smaller homes with lower home values, which can contribute
              to higher energy costs. They are also more likely to live in
              rental housing, which is often not well-maintained.
            </p>
            <br />
            <p>
              <span>Housing Inequities: </span>
              Low-income families are more likely to live in older, less
              energy-efficient houses, particularly in neighborhoods like East
              Gainesville. These outdated homes may have poor insulation,
              inefficient appliances, and outdated heating and cooling systems,
              resulting in higher energy consumption and costs.
            </p>
            <h4>The Impact of Energy Burden</h4>
            <p>
              The high energy burden faced by low-income families can have a
              significant impact on their lives. It can: 
           
            </p>
         
            <p>Limit Access to
              Essential Resources: High energy bills can reduce the amount of
              money available for food, healthcare, education, and other
              necessities.</p>
          
            <p>
              <span >
                Create Financial Hardship:{" "}
              </span>
              The burden of high energy costs can lead to debt, stress, and even
              eviction. 
            </p>
            <p>
            Impact Health and Well-being: Extreme temperatures can
              pose health risks, especially for vulnerable populations. High
              energy bills can make it difficult to maintain a comfortable and
              healthy living environment.
            </p>
            <h4>Addressing Energy Inequity</h4>
            <p>
            To address energy inequity in Alachua County, it is essential to implement policies and programs that reduce energy costs for low-income families and improve the energy efficiency of their homes. This may include: 
            </p>
            <p>Energy Assistance Programs: Providing financial assistance to help low-income families pay their energy bills. </p>
          <p>Weatherization Programs: Offering incentives or subsidies for home weatherization improvements to reduce energy consumption. </p>
          <p>Affordable Housing Initiatives: Supporting the development of affordable, energy-efficient housing. </p>
          <p>Renewable Energy Access: Expanding access to renewable energy options, such as community solar, for low-income families.</p>
          <p>By addressing these issues, Alachua County can work towards a more equitable and sustainable energy future for all its residents.</p>
            <p>
              <span >
                Affordable Housing Initiatives:
              </span>
              Supporting the development of affordable, energy-efficient
              housing. Renewable Energy Access: Expanding access to renewable
              energy options, such as community solar, for low-income families.
            </p>
            <br />
            <p>
              By addressing these issues, Alachua County can work towards a more
              equitable and sustainable energy future for all its residents.
            </p>
         
           
          </Grid>

          <Grid size={{ xs: 12, lg: 6 }} style={{ padding: "2% 2%" }}>
            <img
              className="challenge-pic"
              src={familypicSM}
              alt="Family of three smiling at the camera"
            />
            <Grid size={12} marginTop="2%">
            <h4>Root Causes</h4>
            <p>
              Energy inequity in Alachua County is rooted in a complex interplay
              of factors, including housing disparities, inefficient homes, and
              limited access to energy-efficiency programs. These interconnected
              elements contribute to the disproportionate energy burden faced by
              low-income and marginalized communities.
            </p>
              <h4>Limited Access to Energy-Efficiency Programs</h4>
              <ul className="challenge-list">
                <li className="milestone-spacing">
                  <strong>Low Incomes & Financial Barriers:</strong> Low-income families may
                  face financial constraints that limit their ability to
                  participate in energy-efficiency programs or make necessary
                  home improvements.
                </li>
                <li className="milestone-spacing">
                  <strong>Lack of Awareness:</strong> Many residents may not be
                  aware of available energy-efficiency programs or the benefits
                  they can provide.
                </li>
                <li className="milestone-spacing">
                  <strong>Program Accessibility:</strong> Some programs may have
                  eligibility requirements or geographic restrictions that make
                  them inaccessible to certain populations.
                </li>
              </ul>
           
            <h4>Housing Disparities</h4>
            <ul className="challenge-list">
              <li className="milestone-spacing">
                <strong>Older, Less Efficient Homes:</strong>  Low-income families are more likely to reside in older, less energy-efficient homes, often located in historically marginalized neighborhoods. These homes may have outdated insulation, inefficient appliances, and poorly maintained heating and cooling systems.
              </li>
              <li className="milestone-spacing">
                <strong>Poor Insulation:</strong> Inadequate insulation can lead to significant heat loss in winter and heat gain in summer, increasing energy consumption and costs
              </li>
              <li className="milestone-spacing">
                <strong>Outdated Appliances:</strong> Older appliances are often less energy-efficient than newer models, consuming more energy and contributing to higher utility bills
              </li>
              <li className="milestone-spacing">
                <strong>Inefficient HVAC Systems:</strong> Outdated or poorly
                maintained heating and cooling systems can operate
                inefficiently, leading to increased energy consumption and
                costs.
              </li>
              <li className="milestone-spacing">
                <strong>Rental Housing:</strong> Many low-income families live in rental housing, which may not be well-maintained or have energy-efficient features. Landlords may be reluctant to invest in energy-saving upgrades due to cost or the potential for tenant turnover.
              </li>
              
            </ul>
           

            </Grid>
          </Grid>
        </Grid>
      </section>
      <Footer />
    </main>
  );
}
