import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import empowerlogo from '../Images/EMPOWERLOGO_Color.png';
import { NavLink } from 'react-router-dom';  // Import NavLink instead of Link

function CollapsibleNavbar() {
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary custom-navbar nav-height">
      <Container style={{ display: 'flex' }}>
        <Navbar.Brand href="/">
          <img src={empowerlogo} style={{ width: '160px' }} alt='Empower Logo in color '/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto nav-link-spacing links">
            <Nav.Link 
              as={NavLink} 
              to="/" 
              exact
              style={{ fontSize: '16px', marginRight: '1.5rem' }}
              activeClassName="active-link" // When active, this class is added
            >
              HOME
            </Nav.Link>
          
            <Nav.Link 
              as={NavLink} 
              to="/about" 
              style={{ fontSize: '16px', marginRight: '1.5rem' }}
              activeClassName="active-link"
            >
              ABOUT US
            </Nav.Link>
            <Nav.Link 
              as={NavLink} 
              to="/communityengagement" 
              style={{ fontSize: '16px', marginRight: '1.5rem' }}
              activeClassName="active-link"
            >
              COMMUNITY ENGAGEMENT
            </Nav.Link>
       
            <Nav.Link 
              as={NavLink} 
              to="/cleanenergy" 
              style={{ fontSize: '16px', marginRight: '1.5rem' }}
              activeClassName="active-link"
            >
              SUSTAINABLE ENERGY SOLUTIONS
            </Nav.Link>
            <Nav.Link 
              as={NavLink} 
              to="/greenjobs" 
              style={{ fontSize: '16px', marginRight: '1.5rem' }}
              activeClassName="active-link"
            >
              GREEN JOBS
            </Nav.Link>
            <Nav.Link 
              as={NavLink} 
              to="/getinvolved" 
              style={{ fontSize: '16px', marginRight: '1.5rem' }}
              activeClassName="active-link"
            >
              GET INVOLVED
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CollapsibleNavbar;
